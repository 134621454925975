import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useLikes } from '@/hooks/useLikes';
import { extractPlatform } from '@/utils/extractPlatform';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { EmptyImage } from '@/components/icons/EmptyImage';
import { LikeDown } from '@/components/icons/LikeDown';
import { LikeUp } from '@/components/icons/LikeUp';
import { Wrapper } from '@/components/atoms/Wrapper';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';

interface PromptedContentRowProps {
  title: string;
  postId: number;
  embedUrl: string;
  likesCount: number;
  imageSource: string;
  dislikesCount: number;
  slug: string | null;
  isLocalLikesCount: boolean;
  handleLocalLikesCount: () => void;
}

export const PromptedContentRow = ({
  slug,
  title,
  postId,
  embedUrl,
  likesCount,
  imageSource,
  dislikesCount,
  isLocalLikesCount,
  handleLocalLikesCount,
}: PromptedContentRowProps) => {
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const { currentLikesCount, currentDislikesCount, likedPost, dislikedPost, handleLikeClick, handleDislikeClick } =
    useLikes({
      postId,
      postTitle: title,
      likesCount,
      dislikesCount,
      isLocalLikesCount,
      handleLocalLikesCount,
    });
  const router = useRouter();
  const { pathname } = router;
  const postPath = `/post/${postId}/${slug || ''}`;
  const isPostPathname = pathname === postPath;
  const likedPostStyle =
    '[&>*:first-child]:!stroke-primary-600 [&>*:first-child]:hover:!stroke-primary-500 [&>*:last-child]:hover:!text-primary-500';
  const dislikedPostStyle =
    '[&>*:last-child]:!stroke-red-600 [&>*:last-child]:hover:!stroke-red-500 [&>*:first-child]:hover:!text-red-500';

  const handleRoute = () => {
    if (isPostPathname) return;
    updateInterstitialActionCount();
    window.location.href = postPath;
  };

  return (
    <Wrapper
      onAuxClick={() => navigateToNewWindow(postPath)}
      onClick={handleRoute}
      className="hover:ease-in hover:duration-150 hover:bg-gray-900 cursor-pointer items-center rounded-lg gap-4 p-2"
      fullWidth
      row
    >
      {imageSource && (
        <Wrapper className="w-24 h-24 min-w-96 relative">
          <Image src={imageSource} alt={title} layout="fill" className="object-cover rounded-lg" />
        </Wrapper>
      )}
      {embedUrl && !imageSource && (
        <Wrapper
          onAuxClick={() => navigateToNewWindow(postPath)}
          onClick={handleRoute}
          className="bg-gray-900 rounded-lg"
        >
          {extractPlatform(embedUrl)?.platform === 'instagram' && (
            <Image src="/images/instagram-preview.svg" alt={title} width={96} height={96} />
          )}
          {extractPlatform(embedUrl)?.platform === 'twitter' && (
            <Image src="/images/twitter-preview.svg" alt={title} width={96} height={96} />
          )}
          {extractPlatform(embedUrl)?.platform === 'youtube' && (
            <Image src="/images/youtube-preview.svg" alt={title} width={96} height={96} />
          )}
          {extractPlatform(embedUrl)?.platform === 'tiktok' && (
            <Image src="/images/tiktok-preview.svg" alt={title} width={96} height={96} />
          )}
        </Wrapper>
      )}

      {!embedUrl && !imageSource && (
        <Wrapper
          onAuxClick={() => navigateToNewWindow(postPath)}
          onClick={handleRoute}
          className="w-24 h-24 min-w-96 rounded-lg"
        >
          <EmptyImage className="w-full h-full" />
        </Wrapper>
      )}

      <Wrapper className="gap-2">
        <h4 className={clsx('text-sm font-semibold', 'multipleEllipsis')}>{title}</h4>
        <Wrapper className="items-center text-center gap-1.5" row>
          <button
            onClick={(e) => {
              void handleLikeClick(e);
            }}
            className={clsx(
              '[&>*]:hover:cursor-pointer gap-1.5 px-0.5 rounded-sm flex h-6 [&>*:last-child]:hover:text-gray-300 [&>*:first-child]:stroke-gray-400 [&>*:first-child]:hover:stroke-gray-300',
              likedPost && likedPostStyle,
            )}
          >
            <LikeUp className="w-5 h-5" />
            <span
              className={clsx(
                'text-sm text-gray-400 cursor-default self-center mt-0.5',
                likedPost && '!text-primary-600',
              )}
            >
              {currentLikesCount}
            </span>
          </button>
          <span className={clsx('mx-1', 'text-sm text-gray-400 cursor-default self-center mt-0.5')}>:</span>
          <button
            onClick={(e) => {
              void handleDislikeClick(e);
            }}
            className={clsx(
              '[&>*]:hover:cursor-pointer gap-1.5 px-0.5 rounded-sm flex h-6 [&>*:first-child]:hover:text-gray-300 [&>*:last-child]:stroke-gray-400 [&>*:last-child]:hover:stroke-gray-300',
              dislikedPost && dislikedPostStyle,
            )}
          >
            <span
              className={clsx(
                'text-sm text-gray-400 cursor-default self-center mt-0.5',
                dislikedPost && '!text-red-600',
              )}
            >
              {currentDislikesCount}
            </span>
            <LikeDown className="self-end w-5 h-5" />
          </button>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
