import clsx from 'clsx';

interface ILikeUpProps {
  className?: string;
}

export const LikeUp = ({ className }: ILikeUpProps) => (
  <svg
    width="24"
    height="24"
    className={clsx('stroke-white', className)}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 10.5195L10 1.51953C10.7956 1.51953 11.5587 1.8356 12.1213 2.39821C12.6839 2.96082 13 3.72388 13 4.51953V8.51953H18.66C18.9499 8.51625 19.2371 8.57603 19.5016 8.69475C19.7661 8.81346 20.0016 8.98827 20.1919 9.20705C20.3821 9.42583 20.5225 9.68335 20.6033 9.96178C20.6842 10.2402 20.7035 10.5329 20.66 10.8195L19.28 19.8195C19.2077 20.2964 18.9654 20.7311 18.5979 21.0435C18.2304 21.3559 17.7623 21.525 17.28 21.5195H6M6 10.5195V21.5195M6 10.5195H3C2.46957 10.5195 1.96086 10.7302 1.58579 11.1053C1.21071 11.4804 1 11.9891 1 12.5195V19.5195C1 20.05 1.21071 20.5587 1.58579 20.9337C1.96086 21.3088 2.46957 21.5195 3 21.5195H6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
