import clsx from 'clsx';

interface ILikeDownProps {
  className?: string;
}

export const LikeDown = ({ className }: ILikeDownProps) => (
  <svg
    width="24"
    height="24"
    className={clsx('stroke-white', className)}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 12.5194L12 21.5194C11.2044 21.5194 10.4413 21.2033 9.87871 20.6407C9.3161 20.0781 9.00003 19.3151 9.00003 18.5194V14.5194H3.34003C3.05012 14.5227 2.76297 14.4629 2.49846 14.3442C2.23395 14.2255 1.99842 14.0507 1.80817 13.8319C1.61793 13.6131 1.47753 13.3556 1.39669 13.0772C1.31586 12.7987 1.29652 12.506 1.34003 12.2194L2.72003 3.2194C2.79235 2.7425 3.0346 2.3078 3.40212 1.9954C3.76965 1.683 4.2377 1.51395 4.72003 1.5194H16M16 12.5194V1.5194M16 12.5194H18.67C19.236 12.5294 19.7859 12.3313 20.2154 11.9626C20.645 11.5939 20.9242 11.0803 21 10.5194V3.5194C20.9242 2.95846 20.645 2.44491 20.2154 2.07622C19.7859 1.70754 19.236 1.5094 18.67 1.5194H16"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
