import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';
import { Card } from '@/components/atoms/Card';

export const MediaBox = () => {
  const config = [
    {
      id: 0,
      name: 'Facebook',
      description: '323 tys. obserwujących',
      icon: 'facebook-color.svg',
      alt: 'facebook icon',
      link: 'https://www.facebook.com/FangolPl/',
    },
    {
      id: 1,
      name: 'YouTube',
      description: '127 tys. subskrybentów',
      icon: 'youtube-color.svg',
      alt: 'youtube icon',
      link: 'https://www.youtube.com/@pilkawskrocie',
    },
    {
      id: 2,
      name: 'Instagram',
      description: '39 tys. obserwujących',
      icon: 'instagram-color.svg',
      alt: 'instagram icon',
      link: 'https://www.instagram.com/fangolpl/?hl=en',
    },
    {
      id: 3,
      name: 'Twitter',
      description: 'Nowość! 🔥',
      icon: 'twitter-color.svg',
      alt: 'twitter icon',
      link: 'https://twitter.com/FangolPL',
    },
    {
      id: 4,
      name: 'TikTok',
      description: 'Nowość! 🔥',
      icon: 'tiktok-color.svg',
      alt: 'tiktok icon',
      link: 'https://www.tiktok.com/@fangol',
    },
  ];

  const onClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Card className="!p-0 w-full !border-gray-800" variant="black">
      <Wrapper className="bg-primary-600 text-gray-950 px-4 pt-4 pb-3 rounded-t-lg" fullWidth>
        <p className="font-bold">Śledź Fangola 🤟 👀</p>
        <p className="text-sm">Żywe emocje na ulubionych socialach!</p>
      </Wrapper>
      <Wrapper
        className="[&>div:last-child]:rounded-b-lg [&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-gray-800"
        fullWidth
      >
        {config.map(({ id, name, description, icon, alt, link }) => (
          <Wrapper
            onClick={() => onClick(link)}
            key={id}
            className="hover:bg-gray-950 justify-between cursor-pointer bg-gray-900 px-4 py-4"
            fullWidth
            row
          >
            <Wrapper className="gap-1">
              <p className="font-bold text-white">{name}</p>
              <p className="text-xs font-medium text-gray-300 bg-gray-800 px-2 py-0.5 rounded-xl">{description}</p>
            </Wrapper>
            <Image src={`/icons/${icon}`} alt={alt} width={48} height={48} />
          </Wrapper>
        ))}
      </Wrapper>
    </Card>
  );
};
