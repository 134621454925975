import { useEffect, useState, MouseEvent } from 'react';
import ReactGA from 'react-ga4';

import { postAddLike, postAddDislike, postRemoveDislike, postRemoveLike } from '@/api/posts/posts';
import { useLikesStore } from '@/store/useLikesStore';

interface ILikesProps {
  postId: number;
  postTitle: string;
  likesCount: number;
  dislikesCount: number;
  isLocalLikesCount: boolean;
  handleLocalLikesCount: () => void;
}

export const useLikes = ({
  postId,
  postTitle,
  likesCount,
  dislikesCount,
  isLocalLikesCount,
  handleLocalLikesCount,
}: ILikesProps) => {
  const { likesState, dislikesState, setLikesState, setDislikesState } = useLikesStore((state) => state);
  const [isLikeClick, setIsLikeClick] = useState<boolean>(false);
  const [currentDislikesCount, setCurrentDislikesCount] = useState<number>(dislikesCount);
  const [currentLikesCount, setCurrentLikesCount] = useState<number>(likesCount);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const dislikedPost = dislikesState.filter(({ id }) => id === postId)[0];
  const likedPost = likesState.filter(({ id }) => id === postId)[0];

  const getLikes = () => {
    if (dislikedPost) {
      const initialLikesCount = likesCount < dislikedPost?.likesCount ? dislikedPost?.likesCount : likesCount;
      return initialLikesCount;
    }
    if (likedPost) {
      const initialLikesCount = likesCount < likedPost?.likesCount ? likedPost?.likesCount : likesCount;
      return initialLikesCount;
    }

    return likesCount;
  };

  const getDislikes = () => {
    if (dislikedPost) {
      const initialDislikesCount =
        dislikesCount < dislikedPost?.dislikesCount ? dislikedPost?.dislikesCount : dislikesCount;
      return initialDislikesCount;
    }
    if (likedPost) {
      const initialDislikesCount = dislikesCount < likedPost?.dislikesCount ? likedPost?.dislikesCount : dislikesCount;
      return initialDislikesCount;
    }

    return dislikesCount;
  };

  const removeLike = () => {
    const newLikesState = likesState.filter(({ id }) => id !== postId);
    setLikesState(newLikesState);
  };

  const removeDislike = () => {
    const newDislikesState = dislikesState.filter(({ id }) => id !== postId);
    setDislikesState(newDislikesState);
  };

  const handleLikeClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    ReactGA.event('post_upvote_tap', {
      post_id: postId,
      post_title: postTitle,
      previous_state: likedPost ? 1 : 0,
    });
    if (likedPost || isLikeClick) {
      if (likedPost) {
        removeLike();
        const data = await postRemoveLike(postId);
        setCurrentLikesCount(data.likes);
        setCurrentDislikesCount(data.dislikes);
      }
      return;
    }
    setIsLikeClick(true);

    try {
      if (dislikedPost) {
        removeDislike();
        await postRemoveDislike(postId);
      }
      const data = await postAddLike(postId);
      handleLocalLikesCount();
      setLikesState([...likesState, { id: postId, likesCount: data.likes, dislikesCount: data.dislikes }]);
      setIsLikeClick(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDislikeClick = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    ReactGA.event('post_downvote_tap', {
      post_id: postId,
      post_title: postTitle,
      previous_state: dislikedPost ? 1 : 0,
    });
    if (dislikedPost || isLikeClick) {
      if (dislikedPost) {
        removeDislike();
        const data = await postRemoveDislike(postId);
        setCurrentLikesCount(data.likes);
        setCurrentDislikesCount(data.dislikes);
      }
      return;
    }
    setIsLikeClick(true);

    try {
      if (likedPost) {
        removeLike();
        await postRemoveLike(postId);
      }
      const data = await postAddDislike(postId);
      handleLocalLikesCount();
      setDislikesState([...dislikesState, { id: postId, likesCount: data.likes, dislikesCount: data.dislikes }]);
      setIsLikeClick(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (likedPost?.likesCount && isLocalLikesCount) {
      setCurrentLikesCount(likedPost?.likesCount);
      setCurrentDislikesCount(likedPost?.dislikesCount);
    }
    if (dislikedPost?.dislikesCount && isLocalLikesCount) {
      setCurrentLikesCount(dislikedPost?.likesCount);
      setCurrentDislikesCount(dislikedPost?.dislikesCount);
    }
  }, [likedPost, dislikedPost, isLocalLikesCount]);

  useEffect(() => {
    if ((likedPost?.likesCount || dislikedPost?.dislikesCount) && isInitialLoad) {
      setCurrentDislikesCount(getDislikes());
      setCurrentLikesCount(getLikes());
      setIsInitialLoad(false);
    }
  }, [likedPost, dislikedPost]);

  return {
    currentDislikesCount,
    currentLikesCount,
    dislikedPost,
    likedPost,
    handleDislikeClick,
    handleLikeClick,
  };
};
