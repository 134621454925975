import { HTMLAttributes, ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/utils/cn';

import { Wrapper, WrapperProps } from '../Wrapper';

const cardVariants = cva('md:border md:border-gray-800 rounded-xl px-3 py-6', {
  variants: {
    variant: {
      black: 'bg-gray-950',
      white: 'bg-white',
      gray: 'items-center justify-center gap-2 px-3 bg-zinc-800 text-white',
      darkGray: 'items-center justify-center gap-2 px-3 bg-zinc-900 text-white',
      uploadGray: 'hover:opacity-80 hover:cursor-pointer active:opacity-70 items-center justify-center bg-gray-900',
    },
  },
  defaultVariants: {
    variant: 'black',
  },
});

interface ICard extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

export const Card = ({
  children,
  className,
  variant = 'black',
  ...rest
}: WrapperProps & ICard & VariantProps<typeof cardVariants>) => (
  <Wrapper className={cn(cardVariants({ variant, className }))} {...rest}>
    {children}
  </Wrapper>
);
