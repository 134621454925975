import { Card } from '@/components/atoms/Card';
import { Wrapper } from '@/components/atoms/Wrapper';
import { DownloadButton } from '@/components/atoms/DownloadButton';
import { appStoreLink, googlePlayLink } from '@/data/links';

export const DownloadCard = () => {
  const downloadButtonsConfig = [
    {
      id: 0,
      source: '/icons/google-play-white.svg',
      alt: 'Google Play logo',
      storeName: 'Google Play',
      link: googlePlayLink,
    },
    {
      id: 1,
      source: '/icons/apple-white.svg',
      alt: 'Apple logo',
      storeName: 'AppStore',
      link: appStoreLink,
    },
  ];

  return (
    <Card className="!p-4" variant="black">
      <h3 className="text-xl font-bold mb-2">Bądź zawsze na bieżąco</h3>
      <p className="text-gray-400 text-sm font-light pr-2">
        Przeglądaj świeże newsy jeszcze szybciej – pobierz aplikację Fangol 🚀
      </p>
      <Wrapper className="gap-3 mt-5" fullWidth>
        {downloadButtonsConfig.map(({ id, source, alt, storeName, link }) => (
          <DownloadButton key={id} source={source} alt={alt} storeName={storeName} link={link} />
        ))}
      </Wrapper>
    </Card>
  );
};
