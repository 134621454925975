import { StateCreator, create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { createHookStore } from './index';

interface ILike {
  id: number;
  likesCount: number;
  dislikesCount: number;
}

export interface ILikesStore {
  likesState: ILike[];
  dislikesState: ILike[];
  setLikesState: (value: ILike[]) => void;
  setDislikesState: (value: ILike[]) => void;
}

const initialState = {
  likesState: [],
  dislikesState: [],
  setLikesState: () => {},
  setDislikesState: () => {},
};

const emptyState: StateCreator<ILikesStore> = (set) => ({
  ...initialState,
  setLikesState: (likesState) => set({ likesState }),
  setDislikesState: (dislikesState) => set({ dislikesState }),
});

export const likesStore = create(devtools(persist(emptyState, { name: 'likes-persist' }), { name: 'likes' }));
export const useLikesStore = createHookStore<ILikesStore>(likesStore, initialState);
