export const getSinglePostPath = (postId: number, slug: string, postType: number) => {
  const galleryPath = `/post/${postId}/${slug || ''}/?postType=gallery`;
  const blogpostPath = `/post/${postId}/${slug || ''}/?postType=blogpost`;
  const singlePostPath = `/post/${postId}/${slug || ''}`;
  const singleRankingPath = `/ranking-bukmacherow/${slug}`;

  if (postType === 5) {
    return singleRankingPath;
  }

  if (postType === 4) {
    return galleryPath;
  }

  if (postType === 1) {
    return blogpostPath;
  }

  return singlePostPath;
};
