import { Fragment, useRef } from 'react';
import { ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import { useOnClickOutside } from 'usehooks-ts';
import clsx from 'clsx';

import { DataItem } from '@/api/posts/interfaces';
import { getPostsByLikesOnClient } from '@/api/posts/posts';
import { Wrapper } from '@/components/atoms/Wrapper';

import { IIntervalData } from '../PromptedContentCard/interfaces';

interface ISidebarPopover {
  showPopover: boolean;
  selectedInterval: IIntervalData;
  handlePopover: (value: boolean) => void;
  handlePopoverData: (value: DataItem[]) => void;
  handleSelectedInterval: (value: IIntervalData) => void;
}

export const SidebarPopover = ({
  showPopover,
  selectedInterval,
  handlePopover,
  handlePopoverData,
  handleSelectedInterval,
}: ISidebarPopover) => {
  const popoverRef = useRef(null);

  const config = [
    { id: 0, interval: '24h', text: '24 h' },
    { id: 1, interval: '7d', text: '7 dni' },
    { id: 2, interval: '30d', text: '30 dni' },
    { id: 3, interval: 'allTime', text: 'Zawsze' },
  ];

  const onPopoverClose = () => {
    handlePopover(false);
  };

  const onTimeIntervalClick = async (interval: string, text: string) => {
    onPopoverClose();
    handleSelectedInterval({ interval, text });

    try {
      const response = await getPostsByLikesOnClient(interval);
      handlePopoverData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useOnClickOutside(popoverRef, onPopoverClose);

  return (
    <Popover>
      <Popover.Button
        className={clsx(
          'text-base font-semibold text-white hover:text-primary-600 gap-2 px-2 regular:px-3 py-2 regular:py-3 ml-1 rounded outline-none hover:bg-gray-900 flex items-center',
          showPopover && '!text-primary-600 bg-gray-900',
        )}
        onClick={() => handlePopover(!showPopover)}
      >
        <Wrapper className="items-center gap-2" row>
          <ClockIcon width={22} strokeWidth={2} className="text-primary-600" />
          <p>{selectedInterval.text}</p>
          <ChevronDownIcon width={18} strokeWidth={3} />
        </Wrapper>
      </Popover.Button>
      <Transition
        as={Fragment}
        show={showPopover}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="transform absolute z-10 mt-1 px-0 right-4 w-160" ref={popoverRef}>
          <Wrapper
            className="border border-gray-800 rounded-lg shadow-lg overflow-hidden bg-gray-950 p-2 gap-0.5"
            fullWidth
          >
            {config.map(({ id, interval, text }) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
              <p
                onClick={() => {
                  void onTimeIntervalClick(interval, text);
                }}
                key={id}
                className={clsx(
                  'text-sm font-semibold hover:bg-gray-900 cursor-pointer rounded p-1.5',
                  selectedInterval.interval === interval && 'bg-gray-900',
                )}
              >
                {text}
              </p>
            ))}
          </Wrapper>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
