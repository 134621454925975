import { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { DataItem } from '@api/posts/interfaces';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Card } from '@/components/atoms/Card';
import { Button } from '@/components/atoms/Button';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';

import { PromptedContentRow } from './PromptedContentRow';
import { SidebarPopover } from '../SidebarPopover';
import { IIntervalData } from './interfaces';

interface IPromptedContentCardProps {
  favouritePosts: DataItem[];
  isLocalLikesCount: boolean;
  handleLocalLikesCount: () => void;
}

export const PromptedContentCard = ({
  favouritePosts,
  isLocalLikesCount,
  handleLocalLikesCount,
}: IPromptedContentCardProps) => {
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const [data, setData] = useState<DataItem[]>(favouritePosts);
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [isSidebarPopover, setSidebarPopover] = useState<boolean>(false);
  const [selectedInterval, setSelectedInterval] = useState<IIntervalData>({ interval: '24h', text: '24 h' });
  const showMoreRoute = `/?top=${selectedInterval.interval}`;

  const handlePopover = (value: boolean) => {
    setSidebarPopover(value);
  };

  const handlePopoverData = (value: DataItem[]) => {
    setData(value);
  };

  const onShowMoreClick = () => {
    updateInterstitialActionCount();
    window.location.href = showMoreRoute;
  };

  useScrollPosition(({ currPos }) => {
    if (isSidebarPopover) {
      setScrollPosition(currPos.y);
      if (scrollPosition && scrollPosition !== currPos.y) {
        setSidebarPopover(false);
      }
    }
  });

  return (
    <Card variant="black" className="rounded-xl w-full !p-0">
      <Wrapper className="justify-between items-center border-b border-gray-800 px-4 py-2" fullWidth row>
        <h3 className="font-semibold text-base">Wasze top 🔥</h3>
        <SidebarPopover
          showPopover={isSidebarPopover}
          selectedInterval={selectedInterval}
          handlePopover={handlePopover}
          handlePopoverData={handlePopoverData}
          handleSelectedInterval={setSelectedInterval}
        />
      </Wrapper>
      <Wrapper className="px-1 py-2 sm:p-2" fullWidth>
        {data?.map((post) => {
          const { title, postCoverPicture, likes, dislikes, embedMediaUrl, slug } = post.attributes;
          const picture = postCoverPicture?.data?.attributes?.formats?.medium?.url;

          return (
            <PromptedContentRow
              key={post.id}
              postId={post.id}
              title={title}
              slug={slug}
              embedUrl={embedMediaUrl as string}
              imageSource={picture}
              likesCount={likes}
              dislikesCount={dislikes}
              isLocalLikesCount={isLocalLikesCount}
              handleLocalLikesCount={handleLocalLikesCount}
            />
          );
        })}

        <Button
          onAuxClick={() => navigateToNewWindow(showMoreRoute)}
          onClick={onShowMoreClick}
          variant="outlined"
          className="!border-gray-600 hover:opacity-70 m-2"
        >
          Pokaż więcej
        </Button>
      </Wrapper>
    </Card>
  );
};
