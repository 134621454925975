export const extractPlatform = (url: string) => {
  const youtubeRegex = /youtube\.com\/watch\?v=([^&]+)|youtu\.be\/([^&]+)/;
  const tiktokRegex = /tiktok\.com\/@([^/]+)\/video\/(\d+)/;
  const twitterRegex = /twitter\.com\/[^/]+\/status\/(\d+)/;
  const instagramRegex = /instagram\.com\/p\/([^/]+)/;

  if (youtubeRegex.test(url)) {
    const match = url.match(youtubeRegex);
    return { platform: 'youtube', id: match?.[1] };
  }

  if (twitterRegex.test(url)) {
    const match = url.match(twitterRegex);
    return { platform: 'twitter', id: match?.[1] };
  }

  if (instagramRegex.test(url)) {
    const match = url.match(instagramRegex);
    return { platform: 'instagram', id: match?.[1] };
  }

  if (tiktokRegex.test(url)) {
    const match = url.match(tiktokRegex);
    return { platform: 'tiktok', id: match?.[1] };
  }

  return null;
};
