import clsx from 'clsx';

interface IEmptyImageProps {
  className?: string;
}

export const EmptyImage = ({ className }: IEmptyImageProps) => (
  <svg
    className={clsx('stroke-gray', className)}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="80" height="80" rx="8" fill="#52525B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.8701 32.1598C52.8942 32.1598 54.5351 30.4415 54.5351 28.3218C54.5351 26.2022 52.8942 24.4839 50.8701 24.4839C48.846 24.4839 47.2052 26.2022 47.2052 28.3218C47.2052 30.4415 48.846 32.1598 50.8701 32.1598ZM40.7264 55.4003H18.5709C17.7936 55.4003 17.3135 54.5524 17.7133 53.8859L32.8289 28.6872C33.2113 28.0497 34.1276 28.0329 34.5263 28.6603C36.484 31.7407 41.4092 39.5017 45.5991 46.1967L50.1436 38.5763C50.5112 37.9598 51.374 37.9396 51.7837 38.5379L62.246 53.8172C62.7048 54.4872 62.2587 55.4045 61.4692 55.4125L51.3167 55.5162L40.7264 55.4003Z"
      fill="#27272A"
    />
  </svg>
);
