import Image from 'next/image';
import ReactGA from 'react-ga4';

import { Wrapper } from '../Wrapper';

interface DownloadButtonProps {
  storeName: string;
  source: string;
  link: string;
  alt: string;
}

export const DownloadButton = ({ storeName, source, alt, link }: DownloadButtonProps) => (
  <button
    onClick={() => {
      ReactGA.event('app_store_badge_tap', {
        badge_type: storeName,
      });
      window.open(link, '_blank');
    }}
    className="rounded-lg border border-gray-600 flex items-center justify-center hover:opacity-70 text-white w-full py-2.5"
  >
    <Wrapper className="relative self-start mr-2.5">
      <Image alt={alt} src={source} className="self-start mr-2.5" width={32} height={32} />
    </Wrapper>
    <Wrapper className="text-start">
      <p className="text-xs">Download on the</p>
      <p className="text-base font-bold -mt-1">{storeName}</p>
    </Wrapper>
  </button>
);
